<template>
	<div>
		<v-row class="mot-survey-form-large">
			<v-col>
				<p class="text-subtitle-1 text-left">現在の主な勤務先を１つお選びください。図書館に勤務されている方は勤務先の館種を、それ以外の方は業種をお選びください。<span class="star">*</span></p>
			</v-col>
		</v-row>
		<v-row class="mot-survey-form">
			<v-col>
				<v-select
					v-model="form.occupation"
					label="現在の主な勤務先"
					:items="occupations"
					item-text="text"
					item-value="value"
					:rules="[rules.required]"
				></v-select>
			</v-col>
		</v-row>
		<v-row class="mot-survey-form mot-survey-form-margin-bottom" v-if="form.occupation === 99">
			<v-col>
				<v-text-field
					v-model="form.other_occupation"
					placeholder="例：教育"
					:rules="[rules.max100chars, rules.required]"
					counter
					maxlength="100"
					rows="3"
				>
					<template v-slot:label>
						「その他」と答えた方は、業種をご記入ください。<span class="star">*</span>
					</template>
				</v-text-field>
			</v-col>
		</v-row>
	</div>
</template>

<style scoped>
.mot-survey-form-large {
	margin-top: 0em;
}

.mot-survey-form-small {
	margin-top: -1em;
}

.mot-survey-form-margin-bottom {
	margin-bottom: 0em;
}

.mot-survey-form {
	margin-top: -1em;
}

.note {
	font-size: 0.9em;
	color: #777;
}

.star {
	color: red;
	font-size: 1.2em;
	margin-left: 0.2em;
}

</style>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'UserSurvey',
	data () {
		return {
			form: {
				motivation: null,
				certificate_year: null,
				occupation: null,
				other_occupation: ''
			},
			rules: {
				required: value => !!value || '必須'
			},
			motivations: [
				{ text: "他の科目の履修を犠牲にしてでも、司書資格を取得するつもりであった", value: 1 },
				{ text: "他に取りたい資格や優先したいことがなければ、司書資格を取得するつもりであった", value: 2 },
				{ text: "授業が面白くなければ／課題が大変なら、途中で資格取得はやめるかもしれなかった", value: 3 },
				{ text: "とりあえず課程登録はしたが、司書資格を取得する気はあまりなかった", value: 4 },
			],
			certificate_years: [
				{ text: "1970年代", value: 1 },
				{ text: "1980年代", value: 2 },
				{ text: "1990年代", value: 3 },
				{ text: "2000年代", value: 4 },
				{ text: "2010年代", value: 5 },
				{ text: "2020年代", value: 6 },
			],
			occupations: [
				{ text: "公共図書館", value: 1 },
				{ text: "大学図書館", value: 2 },
				{ text: "学校図書館", value: 3 },
				{ text: "その他の図書館", value: 4 },
				{ text: "図書館関連企業（例：TRC、取次）", value: 5 },
				{ text: "出版", value: 6 },
				{ text: "書店", value: 7 },
				{ text: "情報（コンピュータ関連等）", value: 8 },
				{ text: "マスコミ", value: 9},
				{ text: "教育", value: 10 },
				{ text: "公務員・団体職員・行政", value: 11 },
				{ text: "博物館・美術館", value: 12 },
				{ text: "製造", value: 13 },
				{ text: "商社・流通", value: 14 },
				{ text: "不動産・リース", value: 15 },
				{ text: "サービス", value: 16 },
				{ text: "金融・保険", value: 17 },
				{ text: "その他（自由記述）", value: 99 }
			],
		}
	},
	async created () {
		this.setLoading(true)
		this.clearScaleScore()
		const scaleId = this.test.scales[0].id
		await this.getScale(scaleId).then(() => {
			this.clearScaleItemAnswers()
			this.clearAnsweredScales()
			this.setScaleItemNotAnswered(this.scale.scaleitemorders)
			this.setLoading(false)
			this.scaleitemorders = this.scale.scaleitemorders
			this.startAt = Date.now()
		}).catch(err => {
			this.clearScale()
			this.error = true
			this.setLoading(false)
			console.error(err)
		})
	},
	computed: {
		...mapGetters({
			test: 'stateTest',
			scale: 'stateScale',
			scaleItemAnswered: 'stateScaleItemAnswered',
			scaleItemNotAnswered: 'stateScaleItemNotAnswered',
			scaleItemAnswers: 'stateScaleItemAnswers',
			answeredScales: 'stateAnsweredScales',
		}),
		testId() {
			return this.$route.params.test_id
		},
		scaleLength() {
			return this.scaleitemorders.length
		},
		notAnsweredId() {
			return this.getNotAnsweredId()
		}
	},
	methods: {
		...mapActions([
			'clearScale',
			'getScale',
			'clearScaleItemAnswers',
			'setScaleItemNotAnswered',
			'postScaleItemAnswers',
			'setLoading',
			'setAlert',
			'setAnsweredScales',
			'appendAnsweredScale',
			'clearAnsweredScales',
			'clearScaleItemAnswers',
			'createScaleScore',
			'clearScaleScore',
		]),
		async answer() {
			this.setLoading(true)
			const scaleScore = await this.createScaleScore(
				{
					test_id: this.testId,
					user_id: this.userId,
					testanswer_id: this.testAnswer.id
				}
			)
			const payload = {
				testId: this.testId,
				userId: this.userId,
				startAt: this.startAt,
				scaleScoreId: scaleScore.id
			}
			await this.postScaleItemAnswers(payload).then(() => {
				this.setLoading(false)
				this.appendAnsweredScale(this.scale.id)
			}).catch(err => {
				this.setLoading(false)
				this.setAlert(true)
				console.error(err)
			})
		},
		getNotAnsweredId() {
			return this.scaleItemNotAnswered
		}
	}
}
</script>